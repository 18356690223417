<template>
  <b-row class="d-flex align-items-center justify-content-between">
    <b-col class="d-flex">
      <div
        class="rounded-circle d-flex align-items-center justify-content-center number background-light-color"
      >
        <h4 class=" m-0 text-primary">
          {{ index + 1 }}
        </h4>
      </div>
      <div v-if="question.name" class="d-flex flex-column justify-content-center pl-1">
        <p class="html-text-ellipsis-4 mb-0">
          {{ translatedFieldName(question.name) }}
        </p>
        <ul
          v-if="question.type === 'select' || (question.type === 'radio' && question.options)"
          class="mt-50 p-0"
        >
          <li
            v-for="(option, index) in question.options"
            :key="index"
            class="d-flex mb-25 p-0 border-0 align-items-center"
          >
            <small class="mb-0">
              <span class="text-primary ml-25">
                Op. {{ index + 1 }}:
              </span> 
              {{ translatedFieldName(option) }}
            </small>
          </li>
        </ul>
      </div>
    </b-col>
    <b-col
      cols="12"
      lg="3"

      class="d-flex mx-lg-1 align-items-center mt-50 justify-content-between mt-lg-0"
    >
      <div class="mr-3 ml-4 ml-lg-0 width-150">
        <small>
          {{ $t('backoffice.onboarding-form.questions.type') }}: 
          <span v-if="question.customFieldLink">
            {{ $t('backoffice.onboarding-form.types.custom-field') }} - 
          </span> {{ question.type }} 
        </small>
        <p class="mb-0 small" :class="question.rules === 'required' ? 'text-primary' : 'text-muted'">
          {{
            question.rules === 'required'
              ? $t('backoffice.onboarding-form.questions.required')
              : $t('backoffice.onboarding-form.questions.no-required')
          }}
        </p>
        <p v-if="question.maxAnswers > 1" class="mb-0 small text-primary">
          {{ $t('backoffice.onboarding-form.questions.multiple') }}
        </p>
      </div>
      <!-- Actions -->
      <div class="d-flex mr-50 mr-lg-0">
        <!-- Edit -->
        <feather-icon
          icon="Edit2Icon"
          size="18"
          class="text-primary edit-icon"
          role="button"
          @click="editModal(question)"
        />
        <!-- Delete -->
        <feather-icon
          icon="TrashIcon"
          size="18"
          class="text-primary edit-icon ml-1"
          role="button"
          @click="deleteItem(question)"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'FormQuestion',
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    translatedFieldName(field) {
      for (const row of Object.keys(field)) {
        if (field[row]?.length === 0 || !field[row]) {
          delete field[row];
        }
      }
      return translateTranslationTable(this.selectedLanguage, field);
    },
    editModal() {
      this.$emit('editModal', this.question);
    },
    deleteItem() {
      this.$emit('deleteItem', this.question);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.number {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
}
</style>
