<template>
	<base-pagination-container
		class="members-list-container mt-3"
		:total="total"
		:items="responsesItems"
		:per-page="perPage"
		:load-first-page="true"
		:search-placeholder="$t('members.search-placeholder')"
		searchable
		@load-page="sendSearchString"
	>
		<b-table 
			:items="responsesItems" 
			responsive 
			:fields="fields" 
			:per-page="perPage" 
			primary-key="id" 
			show-empty 
			thead-class="p-0" 
			class="position-relative mt-2"
		>
			<!-- Column: Member -->
			<template #cell(name)="{ item }">
				<b-media class="d-flex align-items-center pl-1 ml-25 mt-25">
					<template>
						<div class="d-flex">
							<user-avatar :user="item.member" size="32" variant="light-primary" />
							<div class="ml-50">
								<p v-if="item.member" class="font-weight-extrabold mb-0">
									{{ `${item.member.name} ${item.member.surname}` }}
								</p>
								<span v-else class="text-muted">---</span>
								<small class="text-muted mb-0 html-text-ellipsis-1">
									{{ item.member ? item.member.headline : null || "--" }}
								</small>
							</div>
						</div>
					</template>
				</b-media>
			</template>
			<!-- Actions -->
			<template #cell(actions)="{ item }">
				<feather-icon icon="Edit2Icon" size="18" class="text-primary ml-2 cursor-pointer" @click="handleEdit(item)" />
				<feather-icon icon="Trash2Icon" size="18" class="text-primary cursor-pointer ml-1" @click="handleDelete(item)" />
				<feather-icon 
					icon="DownloadIcon" 
					size="18" 
					class="text-primary cursor-pointer ml-1" 
					@click="downloadHiddenLinks(item.groupUUID)" 
					:id="'download-responses-button-'+item.groupUUID" 
				/>
			</template>
			<!-- Loading -->
			<template #table-busy>
				<div class="text-center p-5">
					<b-spinner size="2rem" type="grow" class="d-block m-auto m-1" variant="primary" label="Loading..." />
				</div>
			</template>
			<!-- Empty -->
			<template #empty>
				<div class="horizontal-placeholder d-block">
					<div v-if="responses && responses.length === 0 && !isLoading">
						<div>
							<img :src="membersPlaceholder" class="d-block" />
						</div>
						<div>
							<p class="text-primary text-center">
								{{ $t("available.message", { itemName: $t("forums.answer-title") }) }}
							</p>
						</div>
					</div>
					<b-spinner v-else-if="isLoading" size="2rem" small type="grow" class="d-block m-auto m-1" variant="primary" label="Loading..." />
				</div>
			</template>
		</b-table>

		<!-- Edit item modal -->
		<b-modal
			id="form"
			v-model="isModalEditOpened"
			:title="$t('backoffice.onboarding-form.edit-form')"
			:ok-title="$t('form.actions.save')"
			ok-only
			@ok="saveAnswers()"
		>
			<h4 class="text-center mb-1">
				{{ currentItem.name || "--" }} {{ currentItem.surname || "--" }}
			</h4>
			<div v-for="(value, key, index) in currentItem" :key="index" class="mb-1">
				<div v-if="key != 'name' && key != 'surname'">
					<p class="font-weight-bold mb-25">
						{{ key }}
					</p>
					<b-form-input v-if="profile[key].correction" v-model="profile[key].correction" />
					<b-form-input v-else-if="profile[key].response" v-model="profile[key].response" />
					<b-form-input v-else v-model="profile[key]" />
				</div>
			</div>
		</b-modal>
		
		<!-- Delete item modal -->
		<unshare-item-modal
			v-if="currentItem"
			v-model="isDeleteModalVisible"
			model-type="answer"
			@remove-item="deleteItem(profile.key)"
			@modal-invisible="isDeleteModalVisible = false"
		/>
	</base-pagination-container>
</template>

<script>
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import UserAvatar from "@core/components/user/UserAvatar.vue";
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";
import BasePaginationContainer from "@core/components/containers/base/BasePaginationContainer.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import UnshareItemModal from "@/@core/components/modal/UnshareItemModal.vue";

export default {
	name: "BackofficeOnboardingResponses",
	components: {
		UserAvatar,
		BasePaginationContainer,
		UnshareItemModal,
	},
	props: {
		formKey: {
			type: String,
			required: true,
		},
		questions: {
			type: Array,
			required: true,
		},
		fromForms: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isLoading: false,
			answers: [],
			responsesItems: [],
			perPage: 200,
			search: "",
			customNames: [],
			currentItem: "",
			isModalEditOpened: false,
			isDeleteModalVisible: false,
			profile: {},
			timer: false,
			hiddenLinks: {},
		};
	},
	async created() {
		this.isLoading = true;
		this.checkDownloadButtons();
    	this.isLoading = false;
	},
	computed: {
		responses() {
			if (this.$store.getters.onboardingAnswers?.unpaginated?.length > 0) {
				return this.$store.getters.onboardingAnswers?.unpaginated;
			}
			return [];
		},
		total() {
			return this.$store.getters.onboardingAnswers?.meta?.total || 0;
		},
		membersPlaceholder() {
			return MembersPlaceholder;
		},
		fields() {
			return [
				{
					key: "name",
					label: this.$t("user.title"),
					thClass: "pl-2",
				},
			];
		},
		currentLocale() {
			return this.$store.state.locale.currentLocale;
		},
		customFields() {
			if (this.$store.getters.customFieldsBO) {
				const customFields = this.$store.getters.customFieldsBO.unpaginated;
				for (const customField of customFields) {
					customField.text = customField.name[this.currentLocale] || Object.values(customField.name)[0];
				}
				return customFields;
			}
			return [];
		},
		classifiers() {
			if (this.$store.getters.classifiersType) {
				const classifiers = this.$store.getters.classifiersType.unpaginated;
				for (const classifier of classifiers) {
					classifier.text = classifier.name[this.currentLocale] || Object.values(classifier.name)[0];
				}
				return classifiers;
			}
			return [];
		},
	},
	watch: {
		fields(value) {
			return value;
		},
		responses(value) {
			return (this.responsesItems = value.map((item) => item));
		},
	},
	methods: {
		sendSearchString(search) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.getFormAnswers(search);
			}, 800);
		},
		async getFormAnswers({ page, search = "" }) {
			this.isLoading = true;
			try {
				await this.$store.dispatch("getItems", {
					itemType: this.fromForms ? "backoffice/form/answers" : "backoffice/onboarding/answers",
					customName: "onboardingAnswers",
					forceAPICall: true,
					page,
					requestConfig: {
						count: this.perPage,
						formKey: this.formKey,
						communitySlug: this.$route.params.communityId,
						showMemberInfo: true,
						...(search !== "" ? { searchEmail: search } : ""),
						...(search !== "" ? { searchString: search } : ""),
					},
				});
			} catch {
				this.notifyError(this.$t("error-message.load-failed"));
			}
			this.responsesItems = this.responses.map((item) => item);
			//console.log('this.responsesItems', this.responsesItems);
			setTimeout(() => this.createFieldsColumns(), 1000);
			this.isLoading = false;
		},
		createFieldsColumns() {
			this.isLoading = true;
			// console.log('this.responses', this.responses);
			// console.log('this.questions', this.questions);
			this.questions.map((item, index) => {
				this.fields.push({
					key: item.key,
					label: this.translate(item.name),
					sortable: false,
					thClass: "pl-50",
					//formatter: (value) => this.translate(value?.correction) || this.translate(value?.response) || this.translate(value),
				});
			});
			//Add score
			this.fields.push({
				key: 'totalScore',
				label: this.$t('form.score'),
				sortable: true,
				thClass: "pl-50",
				//formatter: (value) => this.translate(value?.correction) || this.translate(value?.response) || this.translate(value),
			});
			this.dataFields();
			this.pushActions();
			this.isLoading = false;
		},
		dataFields() {
			this.isLoading = true;
			const responsesArray = [];
			let hasAttachments = false;
			//console.log('this.responses', this.responses);
			this.responses.map((response, index) => {
				//console.log('response', response);
				let arra = {};
				let totalScore = null;
				const member = response.member;
				arra = { ...arra, ...{member} };
				response.answers.forEach(answer => {
					//console.log('answer', answer);
					//console.log('this.questions', this.questions);
					const obj = {};
					if(answer.questionType === "upload-file"){
						//console.log('This is a file!');
						hasAttachments = true;
						obj[answer.questionKey] = this.getFileName(answer.response);
						answer.groupUUID = response.groupUUID;
						this.addDownloadableLinks(answer);
					}else{
						obj[answer.questionKey] = answer.response;
					}
					arra = { ...arra, ...obj };
					totalScore += Number(answer.score);
				});
				arra = { ...arra, ...{'groupUUID': response.groupUUID, totalScore} };
				responsesArray.push(arra);
			});
			this.responsesItems = responsesArray;
			this.isLoading = false;
			this.checkDownloadButtons();
		},
		checkDownloadButtons() {
			// console.log('Deleting unnecessary download buttons...');
			// console.log(Object.keys(this.hiddenLinks));
			for(let groupUUID of Object.keys(this.hiddenLinks)){
				const elementID = 'download-responses-button-'+groupUUID;
				// console.log('ID to show button', elementID);
				const element = document.getElementById(elementID);
				if(element){
					element.style.hidden = false;
				}else{
					// console.log('Element not found');
				}
			}
		},
		addDownloadableLinks(answer) {
			if(!this.hiddenLinks[answer.groupUUID]){
				this.hiddenLinks[answer.groupUUID] = [];
			}
			this.hiddenLinks[answer.groupUUID].push(answer.response);
		},
		downloadHiddenLinks(groupUUID){
			//console.log('this.hiddenLinks', this.hiddenLinks);
			//e.preventDefault();
			if(!this.hiddenLinks[groupUUID]){
				return 0;
			}
			this.hiddenLinks[groupUUID].forEach(url => {
				console.log('url to open:', url);
    			window.open(url);
			});
		},
		// downloadHiddenLinks(groupUUID){
		// 	console.log('this.hiddenLinks', this.hiddenLinks);
		// 	this.hiddenLinks.forEach(linkID => {
		// 		console.log('linkID', linkID);
		// 		if(linkID.includes(groupUUID)){
		// 			const element = document.getElementById(linkID);
		// 			element.click();
		// 		}
		// 	});
		// 	return 0;
		// },
		pushActions() {
			this.fields.push({
				key: "actions",
				label: this.$t("backoffice.members.actions"),
				sortable: false,
			});
		},
		getFileName(file){
			//1) separate by /
			let fileName = file.split('/').pop();
			//2) delete first 20 chars
			fileName = fileName.slice(20);
			//3) take until ?
			fileName = fileName.split('?').shift();
			//console.log('fileName', fileName);
			return fileName;
		},
		async fetchCustomFields() {
			await this.$store.dispatch("getItems", {
				itemType: "customfield",
				customName: "customFieldsBO",
				forceAPICall: true,
				requestConfig: {
					page: 1,
					perPage: 500,
				},
			});
		},
		handleEdit(item) {
			this.currentItem = { ...item };
			delete this.currentItem.key;
			delete this.currentItem.email;
			delete this.currentItem.avatarURL;
			this.profile = item;
			this.isModalEditOpened = true;
		},
		handleDelete(item) {
			this.currentItem = { ...item };
			this.profile = item;
			this.isDeleteModalVisible = true;
		},
		translate(field) {
			return translateTranslationTable(this.$store.state.locale.currentLocale, field);
		},
		async deleteItem(memberKey) {
			try {
				await this.$store.dispatch("editItem", {
					item: {
						itemType: "deleteFormAnswers",
						requestConfig: {
							formKey: this.formKey,
							memberKey: memberKey,
						},
					},
				});
			} catch {}
			this.currentItem = null;
		},
		async saveAnswers() {
			const diff = [];

			delete this.profile.avatarURL;
			delete this.profile.email;
			Object.entries(this.profile).filter((x, index) => {
				if (!Object.values(this.currentItem).includes(x[1])) {
					diff.push(x);
				}
			});
			diff.splice(0, 1);
			const corrections = diff.map((item) => item[1]);
			const formQuestionKeys = [];
			diff.map((item) => {
				this.questions.map((i) => {
					if (Object.values(i.name).includes(item[0])) {
						formQuestionKeys.push(i.key);
					}
				});
			});
			try {
				await this.$store.dispatch("editItem", {
					item: {
						itemType: "/formAnswers",
						customName: "onboardingAnswers",	//TODO: cambiar nombre, porque se llama onboardingAnswers si son generales de form?
						requestConfig: {
							formKey: this.formKey,
							formQuestionKeys,
							memberKey: this.profile.key,
							corrections,
							languageCode: this.currentLocale,
						},
					},
				});
				this.$toast({
					component: ToastificationContentVue,
					props: {
						title: this.$t("success-message.general-success-edit"),
						icon: "AlertTriangleIcon",
						variant: "success",
					},
				});
			} catch {
				this.$toast({
					component: ToastificationContentVue,
					props: {
						title: this.$t("error-message.general-error"),
						icon: "AlertTriangleIcon",
						variant: "danger",
					},
				});
			}
		},
	},
};
</script>
