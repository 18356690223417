<template>
  <b-modal
    v-if="question"
    id="modal-create-questions-onboarding"
    centered
    no-close-on-backdrop
    hide-footer
    size="lg"
    @show="openModal"
  >
    <!-- <pre>question: {{ question }}</pre> -->
    <template #modal-header>
      <language-selector-header
        :title="
          question.key
            ? $t('backoffice.onboarding-form.edit-question')
            : $t('backoffice.onboarding-form.create-question')
        "
        @closeModal="closeModal"
        @selectLanguage="(language) => (selectedLanguageQuestions = language)"
      />
    </template>
    <div v-if="isLoading" class="m-4 d-flex">
      <b-spinner variant="primary" class="mx-auto" />
    </div>
    <b-form v-if="!isLoading" @submit.prevent="handleCreateItem()">
      <b-form-group
        label-for="type"
        :label="$t('backoffice.onboarding-form.new-question-type')"
      >
        <v-select
          id="type"
          v-model="question.type"
          :clearable="false"
          :searchable="false"
          label="text"
          :reduce="(text) => text.code"
          :placeholder="$t('form.select-placeholder')"
          :options="types"
          @input="changeType"
        />
      </b-form-group>
      <div :key="selectorKey">
        <b-form-group
          v-if="question.type === 'customField'"
          label-for="custom-field"
          :label="$t('backoffice.onboarding-form.types.custom-field')"
        >
          <v-select
            id="custom-field"
            v-model="selectedCustomField"
            :clearable="false"
            :searchable="false"
            label="text"
            :reduce="(customField) => customField"
            :placeholder="$t('form.select-placeholder')"
            :options="customFields"
          />
        </b-form-group>
        <b-form-group
          v-if="question.type === 'classifier'"
          label-for="classifier"
          :label="$t('backoffice.onboarding-form.types.classifier') + ' *'"
        >
          <v-select
            id="classifier"
            v-model="selectedClassifier"
            :clearable="false"
            :searchable="false"
            label="text"
            :reduce="(classifier) => classifier"
            :placeholder="$t('form.select-placeholder')"
            :options="classifiers"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                required
                v-bind="attributes"
                v-on="events"
              >
            </template>
          </v-select>
        </b-form-group>
      </div>
      <!-- QUESTION -->
      <b-form-group
        :label="$t('backoffice.onboarding-form.question') + ' *'"
        label-for="name"
      >
        <b-form-textarea
          v-if="question.name"
          id="name"
          v-model="question.name[selectedLanguageQuestions]"
          class="my-50"
          rows="5"
          required
          no-resize
          maxlength="450"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- PLACEHOLDER -->
      <b-form-group
        :label="$t('backoffice.onboarding-form.new-question-placeholder')"
        label-for="placeholder"
      >
        <b-form-input
          v-if="question.placeholder"
          id="placeholder"
          v-model="question.placeholder[selectedLanguageQuestions]"
          class="my-50"
          maxlength="150"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <!-- CHECKBOXES WITH CONFIGURATIONS -->
      <!-- Can create an organization -->
      <b-form-checkbox
        v-if="question.type === 'organization'"
        v-model="question.canCreateOrganization"
        switch
        :unchecked-value="false"
      >
        {{ $t("backoffice.onboarding-form.questions.can-create") }}
      </b-form-checkbox>
      <!-- My organizations -->
      <b-form-checkbox
        v-if="
          question.type === 'organization' &&
            collective.parentKey != null &&
            !collective.bypass
        "
        v-model="question.showMyOrganizations"
        switch
        :unchecked-value="false"
      >
        {{ $t("backoffice.onboarding-form.questions.my-organizations") }}
      </b-form-checkbox>
      <!-- Is required? -->
      <b-form-checkbox
        v-model="question.rules"
        value="required"
        switch
        unchecked-value=""
      >
        {{ $t("backoffice.onboarding-form.is-required") }}
      </b-form-checkbox>
      <!-- Multiple choice -->
      <b-form-checkbox
        v-if="
          question.type === 'select' ||
            question.type === 'country' ||
            question.type === 'organization' ||
            question.type === 'society'
        "
        v-model="question.maxAnswers"
        :value="100"
        :unchecked-value="1"
        switch
      >
        {{ $t("backoffice.onboarding-form.questions.multiple-choice") }}
      </b-form-checkbox>
      <!-- Auto-correct? -->
      <b-form-checkbox
        v-if="showAutocorrect"
        v-model="autocorrect"
        value="autocorrect"
        switch
        unchecked-value=""
      >
        {{ $t("backoffice.onboarding-form.questions.is-autocorrectable") }}
      </b-form-checkbox>

      <!-- RADIO OR SELECTOR OPTIONS -->
      <div
        v-if="question.type === 'select' || question.type === 'radio'"
        class="mt-2"
      >
        <p class="mt-50" for="options">
          {{ $t("backoffice.onboarding-form.options") }}:
        </p>
        <!--pre>question.plusAnswers: {{ question.plusAnswers }}</pre-->
        <!-- <pre>options: {{ options }}</pre> -->
        <b-form-group>
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="question.plusAnswers"
            name="options"
          >
            <div v-for="(option, index) in question.options" :key="index">
              <div>
                {{ $t("backoffice.onboarding-form.option") }}
                {{ index + 1 }} :
              </div>
              <div class="d-flex">
                <b-form-checkbox
                  :value="option[selectedLanguageQuestions]"
                  :disabled="!autocorrect || !option[selectedLanguageQuestions]"
                >
                </b-form-checkbox>
                <!-- {{ option.text[selectedLanguageQuestions] }} -->
                <b-form-input
                  v-model="option[selectedLanguageQuestions]"
                  style="width:100%;"
                  required
                />
              </div>
            </div>
          </b-form-checkbox-group>
        </b-form-group>

        <!-- <div v-for="(option, index) in question.options" :key="index">
          <pre>option: {{ option }}</pre>
          <b-form-group>
            <label for="options">
              {{ $t("backoffice.onboarding-form.option") }}
              {{ index + 1 }} :
            </label>
            <div class="v-flex" style="display: flex !important;">
              <b-form-checkbox
                v-if="autocorrect"
                id="correctoption"
                :key="option.value"
                v-model="plusAnswers"
                :value="option.value"
                :unchecked-value="0"
                title="Select this option as correct answer"
                style="margin-top: 8px;"
                @change="toggleCorrectOption"
              />
              <b-form-input
                id="options"
                v-model="option[selectedLanguageQuestions]"
                required
              />
            </div>
          </b-form-group>
        </div> -->
        <b-button variant="primary" @click="addOption(question.options)">
          <feather-icon icon="PlusCircleIcon" class="text-white" />
        </b-button>
        <b-button
          v-if="question.options.length > 2"
          variant="primary ml-2"
          @click="deleteOption(question.options)"
        >
          <feather-icon icon="DeleteIcon" class="text-white" />
        </b-button>
      </div>

      <!-- CORRECT ANSWER -->
      <div v-if="autocorrect && showAutocorrectText" class="mt-2">
        <p class="mt-50" for="correctanswer">
          {{ $t("form.correct-answer") }}:
        </p>
        <b-form-group>
          <b-form-input
            id="correctanswer"
            v-model="correctAnswerInput"
            class="my-50"
            maxlength="150"
            :placeholder="$t('form.correct-answer-placeholder')"
          />
        </b-form-group>
      </div>

      <!-- SAVE QUESTION -->
      <b-button
        variant="primary"
        class="float-right"
        type="submit"
        :disabled="disabled(question)"
      >
        <span v-if="isSending">
          <b-spinner
            label="Sending..."
            variant="white"
            class="mr-50"
            small
          />
          {{ $t("backoffice.subscriptions.form.action.submit") }}
        </span>
        <span v-else>{{ $t("form.actions.save") }}</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import vSelect from "vue-select";
import LanguageSelectorHeader from "@core/components/modal/LanguageSelectorHeader.vue";
import { randomString } from "@core/utils/utils";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";

export default {
  name: "ModalCreateQuestion",
  components: {
    vSelect,
    LanguageSelectorHeader,
  },
  mixins: [ToastNotificationsMixin],

  props: {
    question: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSending: false,
      isLoading: false,
      selectedLanguageQuestions: "",
      // options: [{}, {}],
      // correctOptions: [],
      autocorrect: false,
      correctAnswerInput: "",
      key: null,
      selectedClassifier: "",
      selectedCustomField: "",
      selectorKey: randomString(10),
    };
  },
  computed: {
    autocorrect(){
      console.log('this.question', this.question);
      console.log('has correct answer?',this.question.plusAnswers )
      return this.question.plusAnswers ? true : false;
    },
    types() {
      return [
        // Falta afegir el 'file' (no preparat a la vista d'onboarding auth)
        {
          text: this.$t("backoffice.onboarding-form.types.text"),
          code: "text",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.phone"),
          code: "phone",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.number"),
          code: "number",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.email"),
          code: "email",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.date"),
          code: "date",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.country"),
          code: "country",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.checkbox"),
          code: "checkbox",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.infotext"),
          code: "infotext",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.textarea"),
          code: "textarea",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.toggle"),
          code: "boolean",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.select"),
          code: "select",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.radio"),
          code: "radio",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.custom-field"),
          code: "customField",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.classifier"),
          code: "classifier",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.organization"),
          code: "organization",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.society"),
          code: "society",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.upload-file"),
          code: "upload-file",
        },
        // {
        //   text: this.$t("backoffice.onboarding-form.types.download-file"),
        //   code: "download-file",
        // },
      ];
    },
    customFields() {
      if (this.$store.getters.customFieldsBO) {
        const customFields = this.$store.getters.customFieldsBO.unpaginated;
        for (const customField of customFields) {
          customField.text = customField.name[this.currentLocale]
            || Object.values(customField.name)[0];
        }
        return customFields;
      }
      return [];
    },
    classifiers() {
      if (this.$store.getters.classifiersType) {
        const classifiers = this.$store.getters.classifiersType.unpaginated;
        for (const classifier of classifiers) {
          classifier.text = classifier.name[this.currentLocale]
            || Object.values(classifier.name)[0];
        }
        return classifiers;
      }
      return [];
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    showAutocorrect() {
      const autocorrectTypes = [
        'text',
        'number',
        'country',
        'checkbox',
        'boolean',
        'select',
        'radio'
      ];
      return autocorrectTypes.includes(this.question.type);
    },
    showAutocorrectText() {
      const autocorrectTypes = [
        'text',
        'number',
        'country',
        'boolean',
      ];
      return autocorrectTypes.includes(this.question.type);
    }
  },
  watch: {
    // selected(newValue, oldValue) {
    //   // Handle changes in individual checkboxes
    //
    // }
  },
  async created() {
    this.isLoading = true;
    await this.fetchCustomFields();
    await this.fetchClassifiersType();
    this.isLoading = false;
  },
  methods: {
    async changeType() {
      if (
        this.question.type === "classifiers"
        || this.question.type === "customField"
      ) {
        this.selectorKey = randomString(10);
      }
    },
    disabled(question) {
      if (!this.translatedFieldName(question.name)) {
        return true;
      }
      if (question.type === "classifier" && !this.selectedClassifier) {
        return true;
      }
      if (question.type === "customField" && !this.selectedCustomField) {
        return true;
      }
      return false;
    },
    async openModal() {
      this.isLoading = true;
      if (!this.customFields || this.customFields.length <= 0) {
        await this.fetchCustomFields();
      }
      if (!this.classifiers || this.classifiers.length <= 0) {
        await this.fetchClassifiersType();
      }
      if (this.customFields && this.customFields.length > 0) {
        if (this.question.customFieldLink) {
          this.question.type = "customField";
          this.selectedCustomField = this.customFields.find(
            (customField) => customField.key === this.question.customFieldLink.key,
          );
        } else {
          this.selectedCustomField = this.customFields[0];
        }
      }
      if (this.classifiers && this.classifiers.length > 0) {
        if (this.question.classifierTypeLink) {
          this.selectedClassifier = this.classifiers.find(
            (classifierType) => classifierType.key === this.question.classifierTypeLink.key,
          );
        } else {
          this.selectedClassifier = this.classifiers[0];
        }
      }

      this.isLoading = false;
    },
    closeModal() {
      this.$bvModal.hide("modal-create-questions-onboarding");
    },
    translatedFieldName(field) {
      return translateTranslationTable(this.selectedLanguageQuestions, field);
    },
    addOption(options) {
      options.push({});
      // this.options.push({ en: '', es: '', ca: '' });
    },
    deleteOption(options) {
      if (options.length > 2) {
        options.pop();
      }
    },
    async fetchClassifiersType() {
      await this.$store.dispatch("getItems", {
        itemType: "backoffice/classifierTypes",
        customName: "classifiersType",
        forceAPICall: true,
        page: 1,
        perPage: 500,
        requestConfig: {
          modelType: "member",
        },
      });
    },
    async fetchCustomFields() {
      await this.$store.dispatch("getItems", {
        itemType: "customfield",
        customName: "customFieldsBO",
        forceAPICall: true,
        requestConfig: {
          page: 1,
          perPage: 500,
        },
      });
    },
    async handleCreateItem() {
      this.isSending = true;
      try {
        if (!this.form.key) {
          const response = await this.$store.dispatch("createItem", {
            item: {
              itemType: "forms",
              requestConfig: {
                name: this.form.name,
                description: this.form.description,
                isOnboardingForm: 1,
                isPublished:
                  this.questions && this.questions.length > 0
                    ? !!this.form.isPublished
                    : false,
              },
            },
          });
          this.key = response.data;
        }
        // Edit a question
        console.log('Editing a question with key:', this.question.key);
        if (this.question.key) {
          const requestConfig = {
            formKey: this.form.key,
            questionKey: this.question.key,
            name: this.question.name,
            placeholder: this.question.placeholder,
            options:
              this.question.type === "select" || this.question.type === "radio"
                ? this.question.options.length > 0
                  ? this.question.options
                  : null
                : null,

            type: this.question.type,
            onlyQuestion: 1,
            rules:
              !this.question.rules || this.question.rules === ""
                ? null
                : this.question.rules,
            maxAnswers: this.question.maxAnswers,
            minAnswers:
              this.question.rules && this.question.rules.includes("required")
                ? 1
                : 0,
            plusAnswers: this.question.plusAnswers ?? [this.correctAnswerInput]
          };
          if (
            this.question.type === "customField"
            && this.selectedCustomField
          ) {
            requestConfig.customFieldKey = this.selectedCustomField.key;
            requestConfig.type = this.selectedCustomField.type;
          }
          if (this.question.type === "classifier" && this.selectedClassifier) {
            requestConfig.classifierTypeKey = this.selectedClassifier.key;
            requestConfig.maxAnswers = this.selectedClassifier.multiple
              ? 100
              : 1;
          }
          if (this.question.type === "organization") {
            requestConfig.canCreateOrganization = this.question.canCreateOrganization;
            requestConfig.showMyOrganizations = this.question.showMyOrganizations;
          }
          await this.$store.dispatch("editItem", {
            item: {
              itemType: "formQuestions",
              requestConfig,
            },
          });
          this.$emit("editQuestion", this.form.key);
        } else {
          // Create a new question
          const requestConfig = {
            formKey: this.form.key ?? this.key,
            name: this.question.name,
            placeholder: this.question.placeholder,
            options:
              this.question.type === "select" || this.question.type === "radio"
                ? this.question.options.length > 0
                  ? this.question.options
                  : null
                : null,
            type: this.question.type,
            onlyQuestion: 1,
            rules: this.question.rules,
            maxAnswers: this.question.maxAnswers,
            plusAnswers: this.question.plusAnswers ?? [this.correctAnswerInput]
          };

          if (
            this.question.type === "customField"
            && this.selectedCustomField
          ) {
            requestConfig.customFieldKey = this.selectedCustomField.key;
            requestConfig.type = this.selectedCustomField.type;
          }
          if (this.question.type === "classifier" && this.selectedClassifier) {
            requestConfig.classifierTypeKey = this.selectedClassifier.key;
            requestConfig.maxAnswers = this.selectedClassifier.multiple
              ? 100
              : 1;
          }
          await this.$store.dispatch("createItem", {
            item: {
              itemType: "formQuestions",
              orderByDate: 1,
              requestConfig,
            },
          });
        }
        // Aqui
        this.notifySuccess(
          this.$t("backoffice.onboarding-form.messages.success-question"),
        );
        // Resets:
        // this.options = [{}, {}];
        this.correctAnswerInput = "";
        this.$emit("formQuestions", this.formKey);
        this.closeModal();
        this.isSending = false;
      } catch {
        this.isSending = false;
        this.notifyError(this.$t("error-message.general-error"));
      }
    },
  },
};
</script>

<style></style>
